import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import MktoForm from "../../components/mkto-form/mkto-form";
import List from "../../components/custom-widgets/list";
import BestBankDefault from "../../components/best-banks/best-banks-default";

//helper functions
import showMktoForm from "../../helpers/showMktoForm";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import ContactInfo from "../../components/contact-info/contact-info";

const TwoColumnSectionWithImage = ({ isGray, children }) => {
  const { content, image } = children;
  if (isGray) {
    return (
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">{image}</div>
            <div className="col-md-6">{content}</div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="container">
      <div className="row">
        <div className="col-md-6 order-2 order-md-1">{content}</div>
        <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">{image}</div>
      </div>
    </section>
  );
};

const EquipmentFinance = () => {
  const title = "Equipment Finance & Loans";
  const description =
    "WaFd Bank's Equipment Finance team has the expertise and resources to provide you with a variety of leasing tools to meet your unique business needs.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/business-equipment-financing/hero-equipment-financing-01132023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/business-equipment-financing/hero-equipment-financing-01132023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/business-equipment-financing/hero-equipment-financing-01132023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/business-equipment-financing/hero-equipment-financing-01132023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/business-equipment-financing/hero-equipment-financing-01132023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/business-equipment-financing/hero-equipment-financing-01132023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/business-equipment-financing/hero-equipment-financing-01132023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/business-equipment-financing"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-equipment-financing-01132023.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "equipment-finance-hero",
    ...getHeroImgVariables(imgData),
    altText: "Warehouse worker driving a forklift in a storage room.",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Expertise to Help Get the Job Done"
          }
        },
        {
          id: 2,
          button: {
            id: "commercial-banker-form-cta",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Equipment Finance"
    }
  ];

  const leasingEquipmentListData = {
    accountName: "leasing-equipment-list",
    items: [
      {
        id: 1,
        text: "Allows you to reserve funds for other uses"
      },
      {
        id: 2,
        text: "Utilize the most up-to-date technology"
      },
      {
        id: 3,
        text: "Reduced down payment on equipment"
      },
      {
        id: 4,
        text: "Improved expense planning and business cycle flexibility"
      },
      {
        id: 5,
        text: "Customized rent payments to match cash flow or seasonal needs"
      },
      {
        id: 6,
        text: "May provide favorable tax benefits (please consult with your professional tax advisor)"
      }
    ]
  };

  const equipmentLoansListData = {
    accountName: "equipment-loans-list",
    items: [
      {
        id: 1,
        text: "On balance sheet financing"
      },
      {
        id: 2,
        text: "Take advantage of depreciation including Section 179 (talk to your tax advisor)"
      },
      {
        id: 3,
        text: "Terms up to 7 years"
      },
      {
        id: 4,
        text: "Fixed interest rates available"
      }
    ]
  };

  const capExLineOfCreditListData = {
    accountName: "capex-line-of-credit-list",
    items: [
      {
        id: 1,
        text: "Pre-approved line for equipment purchases throughout the year"
      },
      {
        id: 2,
        text: "Interest only payments during purchase term"
      },
      {
        id: 3,
        text: "Flexible term out options"
      }
    ]
  };

  const ContactCommercialCta = ({ id }) => {
    return (
      <button
        id={id}
        className="btn btn-primary w-100 w-sm-auto no-min-width"
        onClick={(e) => {
          e.preventDefault();
          showMktoForm(1067);
        }}
      >
        Contact Us
      </button>
    );
  };
  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <MktoForm />
      <section className="container">
        <h1>Equipment Finance</h1>
        <h3>
          Whether you need financing for equipment expansion or replacement, we're here to provide the resources and
          expertise needed.
        </h3>
        <h3 className="mb-0">
          We have a history of working closely with our clients to build long-term business relationships. We accomplish
          this by providing our partners with top-of-the-line banking products and services that cover every need. Ask
          your banker about account offerings for employees of WaFd business clients.
        </h3>
      </section>
      <TwoColumnSectionWithImage isGray={true}>
        {{
          image: (
            <StaticImage
              quality="100"
              placeholder="blurred"
              className="border-radius-12"
              src="../../images/thumbnail-leasing-equipment-022824.jpg"
              alt="Two men shaking hands in front of heavy construction equipment."
            />
          ),
          content: (
            <>
              <h2 className="text-success font-weight-semi-bold">Why Consider Leasing Equipment?</h2>
              <List {...leasingEquipmentListData} />
              <p>Whether you need off-balance-sheet financing, non-tax capital leases, or TRAC leases, we can help.</p>
              <ContactCommercialCta id="contact-commercial-banking-cta-1" />
            </>
          )
        }}
      </TwoColumnSectionWithImage>

      <TwoColumnSectionWithImage>
        {{
          content: (
            <>
              <h2 className="text-success font-weight-semi-bold">Equipment Loans</h2>
              <List {...equipmentLoansListData} />
              <ContactCommercialCta id="contact-commercial-banking-cta-2" />
            </>
          ),
          image: (
            <StaticImage
              quality="100"
              placeholder="blurred"
              className="border-radius-12"
              src="../../images/thumbnail-equipment-loans-022924.jpg"
              alt="Coworkers looking at a project on a computer in an open office space."
            />
          )
        }}
      </TwoColumnSectionWithImage>

      <TwoColumnSectionWithImage isGray={true}>
        {{
          image: (
            <StaticImage
              quality="100"
              placeholder="blurred"
              className="border-radius-12"
              src="../../images/thumbnail-capex-line-of-credit-022824.jpg"
              alt="Businessmen shaking hands across a table."
            />
          ),
          content: (
            <>
              <h2 className="text-success font-weight-semi-bold">CapEx Line of Credit</h2>
              <List {...capExLineOfCreditListData} />
              <ContactCommercialCta id="contact-commercial-banking-cta-3" />
            </>
          )
        }}
      </TwoColumnSectionWithImage>

      <ContactInfo type="commercial" />
      <BestBankDefault />
    </SecondaryLanding>
  );
};
export default EquipmentFinance;
